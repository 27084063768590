<!--
--------------------------------------------------------------------------
    HomeBlockGrid.vue
--------------------------------------------------------------------------

    Component to show a home block of items in a grid

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div v-if="items.length > 0" class="home-block" :class="{ 'home-block-featured' : homeBlockData.featured }">
        <div class="featured-background" v-if="homeBlockData.featured"></div>
        <h2>{{ homeBlockData.title[$language.current] }}</h2>
        <div class="row" v-for="numRow in homeBlockData.numRows" :key="'homeblock.' + homeBlockData.id + '.row.' + numRow">
            <div :class="itemClass" v-for="numCol in homeBlockData.numItems" :key="'homeblock.' + homeBlockData.id + '.row.' + numRow + '.col.' + numCol">
                <div class="grid-element grid-element-separated-space text-left" v-if="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)]" :ref="'gridElement' + numRow">
                    <a :href="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].link" class="zoom" :target="('_blank' ? !items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].link.startsWith('/') : '_self')" :class="{ 'external' : homeBlockData.showItemsIcon && !items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].link.startsWith('/'), 'internal' : homeBlockData.showItemsIcon && items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].link.startsWith('/') }">
                        <img :src="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].image" :title="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].title" :alt="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].title">
                        <div class="text" :class="{ 'icon' : homeBlockData.showItemsIcon }">
                            <h3 class="featured">{{ items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].title }}</h3>
                            <p v-html="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].text" v-if="items[(numRow - 1) * homeBlockData.numItems + (numCol - 1)].text !== null"></p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="more-link" v-if="homeBlockData.link !== null && homeBlockData.link[$language.current] !== ''">
            <a :href="homeBlockData.link[$language.current]" :target="homeBlockData.newWindow ? '_blank' : '_self'">
                <translate>More...</translate>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home-block-grid',
        props: ['homeBlockData'],
        data() {
            return {
                items: [],
                itemClass: ''
            };
        },
        mounted() {
            // Get or search items
            if (this.homeBlockData.type === 'search') this.search();
            else this.get();

            // Set CSS class
            if (this.homeBlockData.numItems === 1) this.itemClass = 'col text-center';
            else if (this.homeBlockData.numItems === 2) this.itemClass = 'col-12 col-sm text-center';
            else if (this.homeBlockData.numItems === 3) this.itemClass = 'col-12 col-sm text-center';
            else if (this.homeBlockData.numItems === 4) this.itemClass = 'col-12 col-sm-6 col-md-6 col-lg text-center';
            else if (this.homeBlockData.numItems === 5) this.itemClass = 'col-12 col-sm-4 col-md-4 col-lg text-center';

            // Prepare event
            let vm = this;
            this.$root.$on('fixHomeHeights', function() {
                // Adapt height of items of every row
                for (let i = 1; i <= vm.homeBlockData.numRows; i++) vm.adaptHeights('gridElement' + i);
            });
        },
        methods: {
            /**
             * Get home block items
             */
            get() {
                // Reset results
                this.items = [];

                // Get admin menus
                this.axios.get(this.apiURL + '/homeBlocks/' + this.homeBlockData.id + '/items')
                    .then(response => {
                        // Get and prepare items
                        response.data.forEach(i => {
                            let item = {};

                            // Get item data
                            item.image = this.$store.state.config.URL + '/images/' + i.image;
                            item.title = i.title[this.$language.current];
                            item.link = i.link[this.$language.current].replace(this.$store.state.config.URL, '');
                            if (i.text !== null) item.text = i.text[this.$language.current];
                            else item.text = '';

                            this.items.push(item);
                        });
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Search items
             */
            search() {
                // Reset results
                this.items = [];

                // Get admin menus
                this.searchData.query = this.homeBlockData.query[this.$language.current];
                this.searchData.sort = this.homeBlockData.sort[this.$language.current];
                this.searchData.page.pageSize = this.homeBlockData.numItems * 2;
                this.axios.post(this.apiURL + '/items/search', this.searchData)
                    .then(response => {
                        // Get and prepare items
                        response.data.content.forEach(i => {
                            let item = {};

                            // Get image
                            let image = this.getItemFieldValue(i, 'image', this.$language.current, false);
                            if (image !== null) {
                                item.image = image;
                                let openAccess = this.getItemFieldValue(i, 'openAccess', this.$language.current, false);
                                if (openAccess === null || openAccess === '0') item.image = '/images/no-image.png';
                            } else item.image = '/images/no-image.png';

                            // Get title and URL
                            item.title = this.getItemFieldValue(i, 'title', this.$language.current, false);
                            item.link = this.getItemURL(i).replace(this.$store.state.config.URL, '');

                            // Get text (only for activities)
                            if (i.type === 'activity') {
                                item.text = this.getItemFieldValue(i, 'signature', this.$language.current, true);
                                if (item.text !== null) item.text = item.text.join(';');
                            } else item.text = '';

                            // Add item to the list
                            this.items.push(item);
                        });
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Adapt items text height
             */
            adaptHeights(elementId) {
                let maxHeight = 0;
                this.$refs[elementId].forEach(gd => {
                    let gdHeight = gd.offsetHeight * 1;
                    if (gdHeight > maxHeight) maxHeight = gdHeight;
                });

                this.$refs[elementId].forEach(gd => {
                    gd.children.forEach(c => {
                        let imageHeight = c.querySelector('img').offsetHeight;
                        c.querySelector('div.text').style.height = (maxHeight - imageHeight) + 'px';
                    });
                });
            }
        }
    }
</script>