<!--
--------------------------------------------------------------------------
   Home.vue
--------------------------------------------------------------------------

    Component to build homepage

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="home" class="view">
        <loading v-if="loading"></loading>
        <template v-else>
            <div v-for="homeBlock in homeBlocks" :key="homeBlock.id">
                <homeBlockNews :homeBlockData="homeBlock" v-if="homeBlock.type === 'news'" />
                <homeBlockStatistics :homeBlockData="homeBlock" v-else-if="homeBlock.type === 'statistics'" />
                <homeBlockGrid :homeBlockData="homeBlock" v-else-if="homeBlock.mode === 'grid'" />
                <homeBlockCarousel :homeBlockData="homeBlock" v-else-if="homeBlock.mode === 'carousel'" />
                <homeBlockSDG :homeBlockData="homeBlock" v-else-if="homeBlock.type === 'sdg'" />
            </div>
        </template>
    </div>
</template>

<script>
    import HomeBlockNews from '@/components/HomeBlockNews';
    import HomeBlockGrid from '@/components/HomeBlockGrid';
    import HomeBlockCarousel from '@/components/HomeBlockCarousel';
    import HomeBlockStatistics from '@/components/HomeBlockStatistics';
    import HomeBlockSDG from '@/components/HomeBlockSDG';
    import Loading from '@/components/Loading';

    export default {
        name: 'home',
        components: { HomeBlockNews, HomeBlockGrid, HomeBlockCarousel, HomeBlockStatistics, HomeBlockSDG, Loading },
        data() {
            return {
                homeBlocks: [],
                loading: true
            };
        },
        mounted() {
            // Get home block items
            this.get();

            // Set search default values
            this.$store.commit('initsSearch', this.$gettext('All'));

            // Clean breadcrumbs
            this.$store.commit('setBreadcrumbs', []);

            // Set base path
            this.$store.commit('setCurrentPath', '/');

            // Set metadata
            this.setMetadata(this.$gettext(this.$store.state.metadata.title) + '. ' + this.$gettext(this.$store.state.metadata.subtitle), this.$gettext(this.$store.state.metadata.description), this.$gettext(this.$store.state.metadata.keywords));
        },
        methods: {
            /**
             * Get home blocks
             */
            get() {
                // Set loading
                this.loading = true;

                // Reset results
                this.homeBlocks = [];

                // Search changelog
                this.axios.get(this.apiURL + '/homeBlocks')
                    .then(response => {
                        this.loading = false;
                        // Get only block enabled
                        this.homeBlocks = response.data.filter(hb => hb.enabled);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        }
    }
</script>