<!--
--------------------------------------------------------------------------
    HomeBlockStatistics.vue
--------------------------------------------------------------------------

    Component to show a home block of statistics

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="home-block" v-if="loading === 0">
        <h2>{{ homeBlockData.title[$language.current] }}</h2>
        <div class="well">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 text-center statistics-data" v-for="key in Object.keys(statistics)" :key="'statistics.' + key">
                    <div class="value">{{ statistics[key] }}</div>{{ $gettext(key) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home-block-statistics',
        props: ['homeBlockData'],
        data() {
            return {
                loading: -4,
                statistics: {
                    pageviews: 0,
                    uniquePageviews: 0,
                    avgSessionDuration: '',
                    countriesFrom: 0,
                    totalActivities: 0,
                    lastYearActivities: 0
                },
                formData: {
                    query: '+type:activity',
                    facets: [],
                    page: {
                        pageSize: 1,
                        pageIdx: 0
                    }
                }
            }
        },
        mounted() {
            // Get statistics data
            this.get();
        },
        methods: {
            /**
             * Get statistis data
             */
            get() {
                // Get global statistics
                this.axios.get(this.apiURL + '/statistics/global')
                    .then(response => {
                        this.statistics.pageviews = (response.data.find(d => d.statName === 'pageviews').statValue / 1000000).toFixed(2) + ' M';
                        this.statistics.uniquePageviews = (response.data.find(d => d.statName === 'uniquePageviews').statValue / 1000000).toFixed(2) + ' M';
                        this.statistics.avgSessionDuration = this.transformTime(response.data.find(d => d.statName === 'avgSessionDuration').statValue);
                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });

                // Get country statistics
                this.axios.get(this.apiURL + '/statistics/country')
                    .then(response => {
                        this.statistics.countriesFrom = response.data.length;
                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });

                // Get total activities
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        this.statistics.totalActivities = response.data.totalElements.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, this.$language.current === 'en' ? '$1,' : '$1.');
                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });

                // Get last year actvities
                this.formData.query += ' +activity_ca_year:2020';
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        this.statistics.lastYearActivities = response.data.totalElements.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, this.$language.current === 'en' ? '$1,' : '$1.');
                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        }
    }
</script>