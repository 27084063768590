<!--
--------------------------------------------------------------------------
    Loading.vue
--------------------------------------------------------------------------

    Component to show loading in containers

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="loading-part">
        <VueElementLoading :active="true" spinner="ring" color="#007BC0" size="64" :is-full-screen="true" background-color="transparent" :text="'Loading...'|translate" />
    </div>
</template>

<script>
    import VueElementLoading from 'vue-element-loading';

    export default {
        name: 'loading',
        components: { VueElementLoading },
    }
</script>