<!--
--------------------------------------------------------------------------
    HomeBlockNews.vue
--------------------------------------------------------------------------

    Component to show a home block item of news

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="home-block home-news" v-if="homeNews.length > 0">
        <h2>{{ homeBlockData.title[$language.current] }}</h2>
        <div class="row">
            <div class="col-lg-6 col-md-12 featured">
                <div class="grid-element grid-element-shared-space">
                    <a :href="homeNews[0].link[$language.current]" class="zoom" target="_blank">
                        <img :src="homeNews[0].image" :title="homeNews[0].title[$language.current]" :alt="homeNews[0].title[$language.current]">
                        <div class="text">
                            <h3>{{ homeNews[0].title[$language.current] }}</h3>
                            <p v-html="homeNews[0].text[$language.current]"></p>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 grid">
                <div class="row">
                    <div class="col-sm-6 fixed-300">
                        <div class="grid-element grid-element-shared-space">
                            <a :href="homeNews[1].link[$language.current]" class="zoom" target="_blank">
                                <img :src="homeNews[1].image" :title="homeNews[1].title[$language.current]" :alt="homeNews[1].title[$language.current]">
                                <div class="text">
                                    <h3>{{ homeNews[1].title[$language.current] }}</h3>
                                    <p v-html="homeNews[1].text[$language.current]"></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-6 fixed-300">
                        <div class="grid-element grid-element-shared-space">
                            <a :href="homeNews[2].link[$language.current]" class="zoom" target="_blank">
                                <img :src="homeNews[2].image" :title="homeNews[2].title[$language.current]" :alt="homeNews[2].title[$language.current]">
                                <div class="text">
                                    <h3>{{ homeNews[2].title[$language.current] }}</h3>
                                    <p v-html="homeNews[2].text[$language.current]"></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 fixed-300">
                        <div class="grid-element grid-element-shared-space">
                            <a :href="homeNews[3].link[$language.current]" class="zoom" target="_blank">
                                <img :src="homeNews[3].image" :title="homeNews[3].title[$language.current]" :alt="homeNews[3].title[$language.current]">
                                <div class="text">
                                    <h3>{{ homeNews[3].title[$language.current] }}</h3>
                                    <p v-html="homeNews[3].text[$language.current]"></p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-6 fixed-300">
                        <div class="grid-element grid-element-shared-space">
                            <a :href="homeNews[4].link[$language.current]" class="zoom" target="_blank">
                                <img :src="homeNews[4].image" :title="homeNews[4].title[$language.current]" :alt="homeNews[4].title[$language.current]">
                                <div class="text">
                                    <h3>{{ homeNews[4].title[$language.current] }}</h3>
                                    <p v-html="homeNews[4].text[$language.current]"></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-link" v-if="$store.state.config.newsMoreURL !== ''">
            <a :href="$store.state.config.newsMoreURL" target="_blank">
                <translate>More news</translate>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home-block-news',
        props: ['homeBlockData'],
        data() {
            return {
                homeNews: []
            };
        },
        mounted() {
            // Get home news
            this.get();
        },
        methods: {
            /**
             * Get home news
             */
            get() {
                // Reset results
                this.homeNews = [];

                // Get admin menus
                this.axios.get(this.apiURL + '/homeNews/current')
                    .then(response => {
                        // Set table data
                        this.homeNews = response.data.content;
                        this.homeNews.forEach(hn => {
                            // Limi text characters
                            if (hn.text[this.$language.current].length > 100) hn.text[this.$language.current] = hn.text[this.$language.current].replace(/^(.{100}[^\s]*).*/, '$1...');
                        });
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
        }
    }
</script>