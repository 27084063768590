<!--
--------------------------------------------------------------------------
    HomeBlockCarousel.vue
--------------------------------------------------------------------------

    Component to show a home block of items in a carousel

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="home-block carousel" :class="{ 'home-block-featured' : homeBlockData.featured }" v-if="items.length > 0" :ref="'homeBlock.' + homeBlockData.id">
        <div class="featured-background" v-if="homeBlockData.featured"></div>
        <h2>{{ homeBlockData.title[$language.current] }}</h2>
        <VueSlickCarousel v-bind="caroulselSettings" @init="onInitCarousel">
            <div class="carousel-element" v-for="(item, index) in items" :key="'slide.' + homeBlockData.id + '.' + index">
                <a :href="item.link" :target="('_blank' ? item.link.startsWith('/') : '_self')" tabindex="-1">
                    <img :src="item.image" :title="item.title" :alt="item.title" width="100%">
                    <div>
                        <h3>{{ item.title }}</h3>
                        <p v-if="item.text !== ''" v-html="item.text"></p>
                    </div>
                </a>
            </div>
        </VueSlickCarousel>
        <div class="more-link" v-if="homeBlockData.link !== null && homeBlockData.link[$language.current] !== ''">
            <a :href="homeBlockData.link[$language.current]" :target="homeBlockData.newWindow ? '_blank' : '_self'">
                <translate>More...</translate>
            </a>
        </div>
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

    export default {
        name: 'home-block-carousel',
        components: { VueSlickCarousel },
        props: ['homeBlockData'],
        data() {
            return {
                items: [],
                searchData: {
                    query: '*:*',
                    facets: [],
                    sort: '',
                    page: {
                        pageIdx: 0,
                        pageSize: 1,
                        total: 0
                    }
                },
                caroulselSettings: {
                    'arrows': true,
                    'dots': false,
                    'centerMode': true,
                    'centerPadding': '0',
                    'slidesToShow': this.homeBlockData.numItems,
                    'slidesToScroll': this.homeBlockData.numItems,
                    'infinite': true,
                    'responsive': [
                        {
                            'breakpoint': 769,
                            'settings': {
                                'arrows': true,
                                'dots': false,
                                'centerMode': true,
                                'centerPadding': '0',
                                'slidesToShow': this.homeBlockData.numItems > 3 ? 3 : this.homeBlockData.numItems,
                                'slidesToScroll': this.homeBlockData.numItems > 3 ? 3 : this.homeBlockData.numItems,
                                'infinite': true,
                            }
                        },
                        {
                            'breakpoint': 577,
                            'settings': {
                                'arrows': true,
                                'dots': false,
                                'centerMode': true,
                                'centerPadding': '0',
                                'slidesToShow': 1,
                                'slidesToScroll': 1,
                                'infinite': true,
                            }
                        }
                    ]
                }
            };
        },
        mounted() {
            // Get or search items
            if (this.homeBlockData.type === 'search') this.search();
            else this.get();
        },
        methods: {
            /**
             * Get home block items
             */
            get() {
                // Reset results
                this.items = [];

                // Get admin menus
                this.axios.get(this.apiURL + '/homeBlocks/' + this.homeBlockData.id + '/items')
                    .then(response => {
                        // Get and prepare items
                        response.data.forEach(i => {
                            let item = {};

                            // Get item data
                            item.image = this.$store.state.config.URL + '/images/' + i.image;
                            item.title = i.title[this.$language.current];
                            item.link = i.link[this.$language.current].replace(this.$store.state.config.URL, '');
                            if (i.text !== null) item.text = i.text[this.$language.current];
                            else item.text = '';

                            this.items.push(item);
                        });
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Search items
             */
            search() {
                // Reset results
                this.items = [];

                // Get admin menus
                this.searchData.query = this.homeBlockData.query[this.$language.current];
                this.searchData.sort = this.homeBlockData.sort[this.$language.current];
                this.searchData.page.pageSize = this.homeBlockData.numItems * 2;
                this.axios.post(this.apiURL + '/items/search', this.searchData)
                    .then(response => {
                        // Get and prepare items
                        response.data.content.forEach(i => {
                            let item = {};

                            // Get image
                            let image = this.getItemFieldValue(i, 'image', this.$language.current, false);
                            if (image !== null) {
                                item.image = image;
                                let openAccess = this.getItemFieldValue(i, 'openAccess', this.$language.current, false);
                                if (openAccess === null || openAccess === '0') item.image = '/images/no-image.png';
                            } else item.image = '/images/no-image.png';

                            // Get title and URL
                            item.title = this.getItemFieldValue(i, 'title', this.$language.current, false);
                            item.link = this.getItemURL(i).replace(this.$store.state.config.URL, '');

                            // Get text (only for activities)
                            if (i.type === 'activity') {
                                item.text = this.getItemFieldValue(i, 'signature', this.$language.current, true);
                                if (item.text !== null) item.text = item.text.join(';');
                            } else item.text = '';

                            // Add item to the list
                            this.items.push(item);
                        });
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Launch when carousel inits
             */
            onInitCarousel() {
                setTimeout(this.calculateHeight, 1000);
            },
            /**
             * Set same height of carousel elements
             */
            calculateHeight() {
                let maxHeight = 0;

                // Get max height of all elements
                if (this.$refs['homeBlock.' + this.homeBlockData.id]) {
                    let elements = this.$refs['homeBlock.' + this.homeBlockData.id].getElementsByClassName('carousel-element');
                    for (let i = 0; i < elements.length; i++) {
                        if (maxHeight < elements[i].clientHeight) maxHeight = elements[i].clientHeight;
                    }

                    // Set elements height to maxHeight
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].style.height = maxHeight + 'px';
                    }
                }
            }

        }
    }
</script>