<!--
--------------------------------------------------------------------------
    HomeBlockSDG.vue
--------------------------------------------------------------------------

    Component to show a home block of the sustainable development goals

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="home-block-sdg" class="home-block" :class="{ 'home-block-featured' : homeBlockData.featured }">
        <div class="featured-background" v-if="homeBlockData.featured"></div>
        <h2>{{ homeBlockData.title[$language.current] }}</h2>
        <div class="d-flex flex-wrap align-items-stretch justify-content-center">
            <div class="sdg" v-for="(goal, index) in goals[$language.current]" :key="'goal.' + index">
                <a :href="goal.link|translate" :title="goal.title|translate"><img :src="goal.image" :alt="goal.title|translate"></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home-block-sdg',
        props: ['homeBlockData'],
        data() {
            return {
                goals: {
                    en: [
                        { title: '1. No poverty', link: '/aodsp/MS4gTm8gcG92ZXJ0eQ==', image: this.$store.state.config.URL +'/images/sdg/1.png' },
                        { title: '2. Zero hunger', link: '/aodsp/Mi4gWmVybyBodW5nZXI=', image: this.$store.state.config.URL +'/images/sdg/2.png' },
                        { title: '3. Good health and well-being', link: '/aodsp/My4gR29vZCBoZWFsdGggYW5kIHdlbGwtYmVpbmc=', image: this.$store.state.config.URL +'/images/sdg/3.png' },
                        { title: '4. Quality education', link: '/aodsp/NC4gUXVhbGl0eSBlZHVjYXRpb24=', image: this.$store.state.config.URL +'/images/sdg/4.png' },
                        { title: '5. Gender equality', link: '/aodsp/NS4gR2VuZGVyIGVxdWFsaXR5', image: this.$store.state.config.URL +'/images/sdg/5.png' },
                        { title: '6. Clean water and sanitation', link: '/aodsp/Ni4gQ2xlYW4gd2F0ZXIgYW5kIHNhbml0YXRpb24=', image: this.$store.state.config.URL +'/images/sdg/6.png' },
                        { title: '7. Affordable and clean energy', link: '/aodsp/Ny4gQWZmb3JkYWJsZSBhbmQgY2xlYW4gZW5lcmd5', image: this.$store.state.config.URL +'/images/sdg/7.png' },
                        { title: '8. Decent work and economic growth', link: '/aodsp/OC4gRGVjZW50IGpvYnMgYW5kIGVjb25vbWljIGdyb3d0aA==', image: this.$store.state.config.URL +'/images/sdg/8.png' },
                        { title: '9. Industry, innovation and infraestructure', link: '/aodsp/OS4gSW5kdXN0cnksIGlubm92YXRpb24gYW5kIGluZnJhc3RydWN0dXJl', image: this.$store.state.config.URL +'/images/sdg/9.png' },
                        { title: '10. Reduced inequalities', link: '/aodsp/MTAuIFJlZHVjZWQgaW5lcXVhbGl0aWVz', image: this.$store.state.config.URL +'/images/sdg/10.png' },
                        { title: '11. Sustainable cities and communities', link: '/aodsp/MTEuIFN1c3RhaW5hYmxlIGNpdGllcyAmIGNvbW11bml0aWVz', image: this.$store.state.config.URL +'/images/sdg/11.png' },
                        { title: '12. Responsible consumption and production', link: '/aodsp/MTIuIFJlc3BvbnNpYmxlIGNvbnN1bXB0aW9uIGFuZCBwcm9kdWN0aW9u', image: this.$store.state.config.URL +'/images/sdg/12.png' },
                        { title: '13. Climate action', link: '/aodsp/MTMuIENsaW1hdGUgYWN0aW9u', image: this.$store.state.config.URL +'/images/sdg/13.png' },
                        { title: '14. Life below water', link: '/aodsp/MTQuIExpZmUgYmVsb3cgd2F0ZXI=', image: this.$store.state.config.URL +'/images/sdg/14.png' },
                        { title: '15. Life on land', link: '/aodsp/MTUuIExpZmUgb24gbGFuZA==', image: this.$store.state.config.URL +'/images/sdg/15.png' },
                        { title: '16. Peace, justice and strong institutions', link: '/aodsp/MTYuIFBlYWNlIGFuZCBqdXN0aWNlIC0gc3Ryb25nIGluc3RpdHV0aW9ucw==', image: this.$store.state.config.URL +'/images/sdg/16.png' },
                        { title: '17. Partnerships for the goals', link: '/aodsp/MTcuIFBhcnRuZXJzaGlwcyBmb3IgdGhlIGdvYWxz', image: this.$store.state.config.URL +'/images/sdg/17.png' },
                        { title: 'See all', link: '', image: this.$store.state.config.URL +'/images/sdg/all.png' }
                    ],
                    ca: [
                        { title: '1. Fi de la pobresa', link: '/aodsp/MS4gRmkgZGUgbGEgcG9icmVzYQ==', image: this.$store.state.config.URL +'/images/sdg/1.png' },
                        { title: '2. Fam zero', link: '/aodsp/Mi4gRmFtIHplcm8=', image: this.$store.state.config.URL +'/images/sdg/2.png' },
                        { title: '3. Salut i benestar', link: '/aodsp/My4gU2FsdXQgaSBiZW5lc3Rhcg==', image: this.$store.state.config.URL +'/images/sdg/3.png' },
                        { title: '4. Educació de qualitat', link: '/aodsp/NC4gRWR1Y2FjacOzIGRlIHF1YWxpdGF0', image: this.$store.state.config.URL +'/images/sdg/4.png' },
                        { title: '5. Igualtat de gènere', link: '/aodsp/NS4gSWd1YWx0YXQgZGUgZ8OobmVyZQ==', image: this.$store.state.config.URL +'/images/sdg/5.png' },
                        { title: '6. Aigua neta i sanejament', link: '/aodsp/Ni4gQWlndWEgbmV0YSBpIHNhbmVqYW1lbnQ=', image: this.$store.state.config.URL +'/images/sdg/6.png' },
                        { title: '7. Energia assequible i no contaminant', link: '/aodsp/Ny4gRW5lcmdpYSBhc3NlcXVpYmxlIGkgbm8gY29udGFtaW5hbnQ=', image: this.$store.state.config.URL +'/images/sdg/7.png' },
                        { title: '8. Treball decent i creixement econòmic', link: '/aodsp/OC4gVHJlYmFsbCBkZWNlbnQgaSBjcmVpeGVtZW50IGVjb27Dsm1pYw==', image: this.$store.state.config.URL +'/images/sdg/8.png' },
                        { title: '9. Industria, innovació i infraestrcutura', link: '/aodsp/OS4gSW5kw7pzdHJpYSwgaW5ub3ZhY2nDsyBpIGluZnJhZXN0cnVjdHVyYQ==', image: this.$store.state.config.URL +'/images/sdg/9.png' },
                        { title: '10. Reducció de les desigualtats', link: '/aodsp/MTAuIFJlZHVjY2nDsyBkZSBsZXMgZGVzaWd1YWx0YXRz', image: this.$store.state.config.URL +'/images/sdg/10.png' },
                        { title: '11. Ciutats i comunitats sostenibles', link: '/aodsp/MTEuIENpdXRhdHMgaSBjb211bml0YXRzIHNvc3RlbmlibGVz', image: this.$store.state.config.URL +'/images/sdg/11.png' },
                        { title: '12. Producció i consum responsables', link: '/aodsp/MTIuIFByb2R1Y2Npw7MgaSBjb25zdW0gcmVzcG9uc2FibGVz', image: this.$store.state.config.URL +'/images/sdg/12.png' },
                        { title: '13. Acció per al clima', link: '/aodsp/MTMuIEFjY2nDsyBwZXIgYWwgY2xpbWE=', image: this.$store.state.config.URL +'/images/sdg/13.png' },
                        { title: '14. Vida submarina', link: '/aodsp/MTQuIFZpZGEgc3VibWFyaW5h', image: this.$store.state.config.URL +'/images/sdg/14.png' },
                        { title: '15. Vida d\'ecosistemes terrestres', link: '/aodsp/MTUuIFZpZGEgZCdlY29zaXN0ZW1lcyB0ZXJyZXN0cmVz', image: this.$store.state.config.URL +'/images/sdg/15.png' },
                        { title: '16. Pau, justicia i institucions sòlides', link: '/aodsp/MTYuIFBhdSwganVzdMOtY2lhIGkgaW5zdGl0dWNpb25zIHPDsmxpZGVz', image: this.$store.state.config.URL +'/images/sdg/16.png' },
                        { title: '17. Aliança per a aconseguir els objectius', link: '/aodsp/MTcuIEFsaWFuw6dhIHBlciBhIGFjb25zZWd1aXIgZWxzIG9iamV0aXVz', image: this.$store.state.config.URL +'/images/sdg/17.png' },
                        { title: 'Veure tots', link: '', image: this.$store.state.config.URL +'/images/sdg/all.png' }
                    ],
                    es: [
                        { title: '1. Fin de la pobreza', link: '/aodsp/MS4gRmluIGRlIGxhIHBvYnJlemE=', image: this.$store.state.config.URL +'/images/sdg/1.png' },
                        { title: '2. Hamber cero', link: '/aodsp/Mi4gSGFtYnJlIGNlcm8=', image: this.$store.state.config.URL +'/images/sdg/2.png' },
                        { title: '3. Salud y bienestar', link: '/aodsp/My4gU2FsdWQgeSBiaWVuZXN0YXI=', image: this.$store.state.config.URL +'/images/sdg/3.png' },
                        { title: '4. Educación de calidad', link: '/aodsp/NC4gRWR1Y2FjacOzbiBkZSBjYWxpZGFk', image: this.$store.state.config.URL +'/images/sdg/4.png' },
                        { title: '5. Igualdad de género', link: '/aodsp/NS4gSWd1YWxkYWQgZGUgZ8OpbmVybw==', image: this.$store.state.config.URL +'/images/sdg/5.png' },
                        { title: '6. Agua limpia y saneamiento', link: '/aodsp/Ni4gQWd1YSBsaW1waWEgeSBzYW5lYW1pZW50bw==', image: this.$store.state.config.URL +'/images/sdg/6.png' },
                        { title: '7. Energia asequible y no contaminente', link: '/aodsp/Ny4gRW5lcmfDrWEgYXNlcXVpYmxlIHkgbm8gY29udGFtaW5hbnRl', image: this.$store.state.config.URL +'/images/sdg/7.png' },
                        { title: '8. Trabajo decente y crecimiento económico', link: '/aodsp/OC4gVHJhYmFqbyBkZWNlbnRlIHkgY3JlY2ltaWVudG8gZWNvbsOzbWljbw==', image: this.$store.state.config.URL +'/images/sdg/8.png' },
                        { title: '9. Industria, innovació e infraestructura', link: '/aodsp/OS4gSW5kdXN0cmlhLCBpbm5vdmFjacOzbiBlIGluZnJhZXN0cnVjdHVyYQ==', image: this.$store.state.config.URL +'/images/sdg/9.png' },
                        { title: '10. Reducción de las desigualdades', link: '/aodsp/MTAuIFJlZHVjY2nDs24gZGUgbGFzIGRlc2lndWFsZGFkZXM=', image: this.$store.state.config.URL +'/images/sdg/10.png' },
                        { title: '11. Ciudades y comunidades sostenibles', link: '/aodsp/MTEuIENpdWRhZGVzIHkgY29tdW5pZGFkZXMgc29zdGVuaWJsZXM=', image: this.$store.state.config.URL +'/images/sdg/11.png' },
                        { title: '12. Producción y consumo responsables', link: '/aodsp/MTIuIFByb2R1Y2Npw7NuIHkgY29uc3VtbyByZXNwb25zYWJsZXM=', image: this.$store.state.config.URL +'/images/sdg/12.png' },
                        { title: '13. Acción por el clima', link: '/aodsp/MTMuIEFjY2nDs24gcG9yIGVsIGNsaW1h', image: this.$store.state.config.URL +'/images/sdg/13.png' },
                        { title: '14. Vida submarina', link: '/aodsp/MTQuIFZpZGEgc3VibWFyaW5h', image: this.$store.state.config.URL +'/images/sdg/14.png' },
                        { title: '15. Vida de ecosistemas terrestres', link: '/aodsp/MTUuIFZpZGEgZGUgZWNvc2lzdGVtYXMgdGVycmVzdHJlcw==', image: this.$store.state.config.URL +'/images/sdg/15.png' },
                        { title: '16. Paz, justicia e institutciones sólidas', link: '/aodsp/MTYuIFBheiwganVzdGljaWEgZSBpbnN0aXR1Y2lvbmVzIHPDs2xpZGFz', image: this.$store.state.config.URL +'/images/sdg/16.png' },
                        { title: '17. Alianzas para lograr los objetivos', link: '/aodsp/MTcuIEFsaWFuemFzIHBhcmEgbG9ncmFyIGxvcyBvYmpldGl2b3M=', image: this.$store.state.config.URL +'/images/sdg/17.png' },
                        { title: 'Ver todoas', link: '', image: this.$store.state.config.URL +'/images/sdg/all.png' }
                    ]
                }
            }
        },
        mounted() {
            let vm = this;

            // Update link of sdg when sections are loaded
            this.$root.$on('sectionsLoaded', function(sections) {
                vm.updateLinks(sections);
            });
            if (this.$store.state.sections) this.updateLinks(this.$store.state.sections);
        },
        methods: {
            /**
             * Update goal links with section path
             */
            updateLinks(sections) {
                // Search for SDG section in english
                let sectionSDG = sections.find(s => s.fullName['en'].toLowerCase().includes('development goals'));
                if (sectionSDG) this.goals[this.$language.current].forEach(g => g.link = sectionSDG.path[this.$language.current] + g.link);
            }
        }
    }
</script>